@use '@styles/functions' as func;
@use '@styles/mixins/breakpoints' as breakpoints;

$wrapper-padding: func.px-to-rem(16px);

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: func.get-color(white);
  flex-wrap: nowrap;
  gap: func.spacing(2);
  border: 1px solid func.get-color(alto);
  margin-bottom: func.spacing(1);

  &Wrapper {
    width: 100%;

    &NoReorder {
      background: func.get-color(wildSand);
    }
  }

  &Title {
    width: 100%;
    display: flex;
    gap: func.spacing(2);
    font-size: func.px-to-rem(16px);
    line-height: 24px;
    background: func.get-color(white);
    margin: 0;
    padding: func.spacing(4, 6);

    &Text {
      display: flex;
      gap: func.spacing(2);
    }

    &WithButton {
      align-items: center;
      justify-content: space-between;
    }

    &NonReorder {
      flex-grow: 2;
      text-align: right;
      font-weight: 400;
    }

    &NonReorderBackground {
      background: func.get-color(wildSand);
      border-bottom: 1px solid func.get-color(alto);
    }
  }

  &DeliveryDate {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: func.spacing(4);
    font-size: func.px-to-rem(15px);
    line-height: func.px-to-rem(25px);

    &Bold {
      font-weight: 700;
      margin-left: func.spacing(1);
    }

    &Decorated {
      background: func.get-color(light, blue);
      width: 100%;
      line-height: func.px-to-rem(25px);
      padding: func.spacing(1, 6);
    }

    &NonReorder {
      background: func.get-color(wildSand);
    }
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: func.spacing(4);

  @include breakpoints.up(md) {
    flex-direction: row;
    align-items: center;
    &Vertical {
      flex-direction: column;
    }
  }

  & > div:first-of-type {
    max-width: auto;
    @include breakpoints.up(md) {
      max-width: 311px;
    }
  }

  &Section {
    display: flex;
    align-items: baseline;
    padding-bottom: func.spacing(2.5);
    padding-top: func.spacing(2.5);
    border-bottom: 1px solid func.get-color(alto);
    width: 100%;
    font-size: func.px-to-rem(14px);
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  &Title {
    font-size: func.px-to-rem(14px);
    text-transform: uppercase;
    margin: 0;
  }
}

div .dropdown {
  overflow: hidden;
  padding: func.spacing(4, 0);
}

div .dropdownContainer {
  border-bottom: solid 1px func.get-color(alto);
  padding: 0;
}

.attributesListContainer {
  justify-content: space-around;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;
}

.attributesListItem {
  display: flex;
  font-size: 0.75rem;
  :nth-child(1) {
    width: 130px;
    min-width: 130px;
  }
}
