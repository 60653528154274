@use '@styles/functions' as functions;
@use '@styles/mixins/_breakpoints' as breakpoints;

/*
NOTE: this is default styling for CMS carousel product tile.
*/

.wrapper {
  color: var(--text-primary-dark);
  font-size: 0.75rem;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 6px 12px 0 rgba(96, 97, 112, 0.16);
  width: calc(100% - 32px);
  max-width: 304px;
  overflow: hidden;

  :global(.arn-product-tile) {
    position: relative;
    aspect-ratio: 1/1;

    @supports not (aspect-ratio: 1 / 1) {
      min-height: 356px;
    }
  }

  @include breakpoints.down(md) {
    height: 100%;
    justify-content: space-between;
    max-width: 400px;
    padding: functions.spacing(0);
  }

  @include breakpoints.up(md) {
    font-size: 1rem;

    :global(&:hover) {
      & > div {
        visibility: visible;
      }
    }
  }
}

.subwrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &::before {
    padding-top: 0;
    content: '';

    @include breakpoints.up(md) {
      display: block;
    }
  }

  @include breakpoints.down(md) {
    & img {
      max-height: 150px;
    }
  }
}

.badgeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 2rem;
  justify-content: space-between;

  .primaryBadge {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    padding: functions.spacing(0, 4);
  }

  .secondaryBadges {
    font-size: 0.875rem;
    font-weight: normal;
    margin-top: functions.spacing(1);
  }
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  > a::after {
    height: 0;
  }
}

.imageAnchor {
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
}

.imageWrapper {
  aspect-ratio: 2 / 1;
  overflow: hidden;
  position: relative;
  display: inline-block;
  font-size: 0; /* Removes default font size for the element */

  @include breakpoints.down(md) {
    height: 100%;
  }

  @include breakpoints.up(lg) {
    :global(.lazy-load-image-loaded) {
      height: 100%;
      width: 100%;
      transition: transform 0.5s; /* Transition for image transformation on hover */
    }

    &:hover :global(.lazy-load-image-loaded),
    &:focus :global(.lazy-load-image-loaded) {
      transform: scale(1.2); /* Applies a 1.2x scale on hover/focus */
    }
  }
}

.imageZoomOnHover {
  overflow: hidden;
  position: relative;
  display: inline-block;
  font-size: 0;

  @include breakpoints.up(md) {
    transition: transform 0.5s;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }

  img {
    height: 100%;
    max-height: none;
    object-fit: scale-down;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: functions.spacing(1);
  padding: 0;
  align-items: flex-start;
  text-align: center;

  &:global(.isPDP) {
    align-items: center;
  }
}

.productName {
  font-size: 0.875rem;
  line-height: functions.px-to-rem(21px);
  text-align: center;
  text-transform: capitalize;
  text-wrap: wrap;
}

.brandName {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: capitalize;
}

.footerWrapper {
  color: var(--text-primary-dark);
  flex: 1;
  position: relative;
  margin: functions.spacing(6, 4, 2);

  &[product-type='contact-lenses'] {
    height: 100%;
  }

  > a::after {
    height: 0;
  }
}

.footer {
  color: var(--text-primary-dark);
  padding: functions.spacing(3);
  padding: functions.spacing(0, 4);

  @include breakpoints.down(md) {
    padding: 0;
  }

  @include breakpoints.up(md) {
    & > div {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex: 0;
    }
  }
}
