@use '@styles/mixins/breakpoints' as breakpoints;

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}


.description {
  font-weight: bold;
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0;
}

.formFields {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1rem;

  @include breakpoints.down(md) {
    flex-direction: column;
  }
}


.column {
  flex-direction: column;
  gap: 2rem;
}

.row {
  flex-direction: row;
}

.inputField {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
}

